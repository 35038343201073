import { HttpClient } from '@angular/common/http';
import { Injectable,inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject,Subject,forkJoin } from 'rxjs';
import { CpRoutes } from 'src/app/cp-route-constants';
import { environment } from '../../../environments/environment';
import {
  ActiveRouteInfo,
  Client,
  UserDetailsAPIPayload,
  UserInfo,
  UserModule,
} from './model.interface';
@Injectable({
  providedIn: 'root',
})
export class ClientPortalService {
  clientConfigUrl = environment.clientConfigUrl;
  userConfigUrl = environment.userConfigUrl;
  logInUserModules = new BehaviorSubject<any>(null);
  logInUserDetails = new BehaviorSubject<any>([]);
  currentSelectedClient = new BehaviorSubject<string | null>(null);
  warehouseUpdated$ = new Subject<boolean>();
  permittedMenuItems: any;
  http = inject(HttpClient);
  _snackBar = inject(MatSnackBar);
  private transformedUserDetails: {
    userInfo: UserInfo;
    modules: any;
    allowedClients: Array<Client>;
    permittedRoutes: { [key: string]: { [key: string]: ActiveRouteInfo } };
    allowedAcrnms: { [key: string]: string[] };
  };
  getActiveClient() {
    return this.currentSelectedClient.getValue();
  }
  getClientList() {
    const company = {
      company_name: sessionStorage.getItem('companyname')?.trim(),
    };
    return this.http.post(this.clientConfigUrl + 'clientlist',company);
  }
  getClientDetails(clientArconym) {
    const payLoad = {
      company_name: sessionStorage.getItem('companyname')?.trim(),
      client_acrm: clientArconym,
    };
    return this.http.post(this.clientConfigUrl + 'clientdetails',payLoad);
  }
  saveClientList(payload) {
    return this.http.post(this.clientConfigUrl + 'savedetails',payload);
  }
  openSnackBar(message: string) {
    // eslint-disable-next-line no-underscore-dangle
    this._snackBar.open(message,'close',{
      duration: 5000,
      panelClass: ['mat-primary','mat-toolbar'],
    });
  }
  getUserList() {
    const payLoad = {
      company_name: sessionStorage.getItem('companyname'),
      uuid: sessionStorage.getItem('loginuseruuid'),
    };
    return this.http.post(this.userConfigUrl + 'userlist',payLoad);
  }
  updateUserTableFromClient(payLoad) {
    const res = this.http.post(
      this.clientConfigUrl + 'clientuserupdate',
      payLoad
    );
    return forkJoin([res]);
  }
  saveAndUpdate(payLoadForSave,payLoadForClientuserupdate) {
    const savedetails = this.http.post(
      this.clientConfigUrl + 'savedetails',
      payLoadForSave
    );
    const clientuserupdate = this.http.post(
      this.clientConfigUrl + 'clientuserupdate',
      payLoadForClientuserupdate
    );
    return forkJoin([savedetails,clientuserupdate]);
  }
  getUserDetails(payLoad: UserDetailsAPIPayload) {
    /**
     * we are using below end point in two cases
     * 1) to get modules of logged in user from which we will build menus
     * 2) in user mgmt component when we want to fetch modules of specific user
     */
    //previous url https://radial-st1.bpost.cloud/api/radial/test
    return this.http.post(this.userConfigUrl + 'userdetails',payLoad);
  }
  saveUserDetails(payLoad) {
    return this.http.post(this.userConfigUrl + 'savedetails',payLoad);
  }
  getModulesByClient(client: string) {
    return window.deepClone(this.transformedUserDetails?.modules[client]) || [];
  }
  getAllowedClientsForLoggedInUser(): Client[] {
    return this.transformedUserDetails?.allowedClients;
  }
  getPermittedRoute(client: string) {
    const data = this.transformedUserDetails?.permittedRoutes[client];
    const permittedRoutes = data ? Object.keys(data) : [];
    if (permittedRoutes.length > 0) {
      const role = this.transformedUserDetails?.userInfo?.role_acronym;
      const shouldAddCCF = role === 'ADM';
      const shouldAddUCF = shouldAddCCF || ['AMR','SUR'].includes(role);
      if (shouldAddUCF) {
        permittedRoutes.push(`/${CpRoutes.UserConfig}`);
      }
      if (shouldAddCCF) {
        permittedRoutes.push(`/${CpRoutes.ClientConfig}`);
      }
    }
    return permittedRoutes;
  }
  getActiveRouteInfo(client: string,url: string): ActiveRouteInfo | null {
    const data = this.transformedUserDetails?.permittedRoutes[client];
    return data && data[url] ? data[url] : null;
  }
  getLoggedInUserInfo(): UserInfo {
    return this.transformedUserDetails?.userInfo;
  }
  isAcronymAllowed(clientName: string,acrnym: string): boolean {
    return this.transformedUserDetails?.allowedAcrnms[clientName]?.includes(
      acrnym
    );
  }
  findParent(parts,menu) {
    if (parts.length === 1) {
      return menu;
    }
    for (const item of menu) {
      /*parts.slice(0, -1) will return first n-1 elements.
      for example [0,1].slice(0,-1) will give[0];
      [1,2,3].slice(0,-1) will give [1,2]
      */
      if (item.id.toString() === parts.slice(0,-1).join('')) {
        item.children = item.children || (item.children = []);
        return item;
      }
      if (item.children) {
        const found = this.findParent(parts,item.children);
        if (found) {
          return found;
        }
      }
    }
    return null;
  }
  createGroupFromModules(
    modules: UserModule[],
    excludeGrp: string[] = [],
    excludeMenu: string[] = ['CIND']
  ): {
    result: Array<any>;
    permittedRoutes: { [key: string]: ActiveRouteInfo };
    allowedAcrnms: string[];
  } {
    const result = [];
    const permittedRoutes: { [key: string]: ActiveRouteInfo } = {};
    const allowedAcrnms = [];
    modules
      .filter((mod) => !(excludeGrp.includes(mod.module_group) || excludeMenu.includes(mod.module_acronym)))
      .forEach((value: UserModule) => {
        if (value.module_order && value.module_type === 'menu') {
          const parts = value.module_order.toLocaleString().split('');
          const id = value.module_order;
          const newItem = { ...value,id,children: [] };
          if (parts.length === 1) {
            delete newItem.children;
            permittedRoutes[`${newItem.routing_url}`] = {
              id,
              title: newItem.module_name,
            };
            allowedAcrnms.push(newItem.module_acronym);
            result.push(newItem);
          } else {
            if (!result.find((el) => el.id === parts[0])) {
              const item = {
                ...value,
                id: parts[0],
                children: [],
                isExpanded: false,
              };
              delete item.actions;
              result.push(item);
            }
            const parentArr = this.findParent(parts,result);
            if (parentArr) {
              permittedRoutes[`${newItem.routing_url}`] = {
                id: newItem.id,
                title: newItem.module_name,
              };
              allowedAcrnms.push(newItem.module_acronym);
              parentArr['isExpanded'] = false;
              parentArr.children.push(newItem);
            }
          }
        } else {
          this.addActionsToModule(result,value,allowedAcrnms);
        }
      });
    return {
      result,
      permittedRoutes,
      allowedAcrnms,
    };
  }
  addActionsToModule(
    modules: Array<UserModule>,
    actionModule: UserModule,
    allowedAcrnms: string[]
  ) {
    const add = (mods: Array<UserModule>,actionMod: UserModule) => {
      mods.forEach((mod) => {
        if (!mod.children || mod.children.length === 0) {
          if (mod.module_acronym === actionMod.module_group) {
            if (!mod.actions) {
              mod.actions = [];
            }
            mod.actions.push(actionMod);
            allowedAcrnms.push(actionMod.module_acronym);
            return;
          }
        } else {
          add(mod.children,actionMod);
        }
      });
    };
    add(modules,actionModule);
  }
  processUserDetailsAPIResponse(userDetailsResponse) {
    const userInfo: any = {
      email: userDetailsResponse[0].email,
      role_acronym: userDetailsResponse[0].role_acronym,
      role_name: userDetailsResponse[0].role_name,
      uuid: userDetailsResponse[0].uuid,
    };
    const modules = {};
    const allowedClients = [];
    const permittedRoutesPerClient: {
      [key: string]: { [key: string]: ActiveRouteInfo };
    } = {};
    const allowedAcrnmsPerClient = {};
    Object.values(userDetailsResponse).forEach((userDetails: any) => {
      modules[userDetails.client_acronym] = {};
      permittedRoutesPerClient[userDetails.client_acronym] = {};
      const { result,permittedRoutes,allowedAcrnms } =
        this.createGroupFromModules(userDetails.modules);
      modules[userDetails.client_acronym] = result;
      permittedRoutesPerClient[userDetails.client_acronym] = permittedRoutes;
      allowedAcrnmsPerClient[userDetails.client_acronym] = allowedAcrnms;
      allowedClients.push({
        client_name: userDetails.client_name,
        client_acronym: userDetails.client_acronym,
      });
    });
    //end
    this.transformedUserDetails = {
      userInfo,
      modules,
      allowedClients,
      permittedRoutes: permittedRoutesPerClient,
      allowedAcrnms: allowedAcrnmsPerClient,
    };
  }
  addConfigMenuOptions(modules: UserModule[]) {
    let areConfigMenusAdded = false;
    const role = this.transformedUserDetails?.userInfo?.role_acronym;
    const shouldAddCCF = role === 'ADM';
    const shouldAddUCF = shouldAddCCF || ['AMR','SUR'].includes(role);
    if (!(shouldAddUCF || shouldAddCCF)) {
      return modules;
    }
    const clonedModules = window.deepClone<UserModule[]>(modules);
    for (let i = clonedModules.length - 1; i >= 0; i--) {
      if (clonedModules[i].module_group === 'Config') {
        const id =
          parseInt(
            clonedModules[i].children[
              clonedModules[i].children.length - 1
            ].module_order.toString()
          ) + 1;
        clonedModules[i].children.push(
          ...this.#addConfigs(shouldAddCCF,shouldAddUCF,id)
        );
        areConfigMenusAdded = true;
        break;
      }
    }
    if (!areConfigMenusAdded) {
      /**
       * if config group is not coming from backend then this code will be helpful
       */
      const moduleId =
        parseInt(
          clonedModules[clonedModules.length - 1].module_order.toString()
        ) + 10; //+10 as we are maintaing order as 21 31 41 ...
      clonedModules.push({
        module_acronym: 'PARMTR',
        module_type: 'menu',
        module_name: 'Parameter',
        module_order: moduleId,
        module_group: 'Config',
        routing_url: '/config/parameter',
        id: moduleId,
        children: (() => {
          return this.#addConfigs(shouldAddCCF,shouldAddUCF,moduleId);
        })(),
        isExpanded: false,
      });
    }
    return clonedModules;
  }
  #addConfigs(
    shouldAddCCF: boolean,
    shouldAddUCF: boolean,
    id
  ): Array<UserModule> {
    const children = [];
    if (shouldAddUCF) {
      children.push({
        module_acronym: 'USRCONIG',
        module_type: 'menu',
        module_name: 'User config',
        module_order: id,
        module_group: 'Config',
        routing_url: '/config/user-config',
        id: id,
      });
    }
    if (shouldAddCCF) {
      children.push({
        module_acronym: 'CLIENTCONFIG',
        module_type: 'menu',
        module_name: 'Client config',
        module_order: id,
        module_group: 'Config',
        routing_url: '/config/client-config',
        id: id + 1,
      });
    }
    return children;
  }
}
